@font-face {
  font-family: "printerLogo";
  src: url("../fonts/printer-logos/printerLogo.eot");
  src: url("../fonts/printer-logos/printerLogo.eot#iefix") format("embedded-opentype"), url("../fonts/printer-logos/printerLogo.ttf") format("truetype"), url("../fonts/printer-logos/printerLogo.woff") format("woff"), url("../fonts/printer-logos/printerLogo.svg#printerLogo") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=plogo-], [class*=" plogo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "printerLogo" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.plogo-apollo:before {
  content: "\e900";
}

.plogo-apple:before {
  content: "\e901";
}

.plogo-astrojet:before {
  content: "\e902";
}

.plogo-brother:before {
  content: "\e903";
}

.plogo-bryce:before {
  content: "\e904";
}

.plogo-canon:before {
  content: "\e905";
}

.plogo-compaq:before {
  content: "\e906";
}

.plogo-dell:before {
  content: "\e907";
}

.plogo-epson:before {
  content: "\e908";
}

.plogo-hasler:before {
  content: "\e909";
}

.plogo-hp:before {
  content: "\e90a";
}

.plogo-ibm:before {
  content: "\e90b";
}

.plogo-kodak:before {
  content: "\e90c";
}

.plogo-konica-minolta:before {
  content: "\e90d";
}

.plogo-kyocera-mita:before {
  content: "\e90e";
}

.plogo-lexmark:before {
  content: "\e90f";
}

.plogo-mimaki:before {
  content: "\e910";
}

.plogo-mutoh:before {
  content: "\e911";
}

.plogo-neopost:before {
  content: "\e912";
}

.plogo-okidata:before {
  content: "\e913";
}

.plogo-panasonic:before {
  content: "\e914";
}

.plogo-pitney-bowes:before {
  content: "\e915";
}

.plogo-rena:before {
  content: "\e916";
}

.plogo-ricoh:before {
  content: "\e917";
}

.plogo-roland:before {
  content: "\e918";
}

.plogo-samsung:before {
  content: "\e919";
}

.plogo-secap:before {
  content: "\e91a";
}

.plogo-sharp:before {
  content: "\e91b";
}

.plogo-xerox:before {
  content: "\e91c";
}