@import "variables/variables";

@font-face {
    font-family: 'printerLogo';
    src: url('../fonts/printer-logos/printerLogo.eot');
    src: url('../fonts/printer-logos/printerLogo.eot#iefix') format('embedded-opentype'),
    url('../fonts/printer-logos/printerLogo.ttf') format('truetype'),
    url('../fonts/printer-logos/printerLogo.woff') format('woff'),
    url('../fonts/printer-logos/printerLogo.svg#printerLogo') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="plogo-"], [class*=" plogo-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'printerLogo' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.plogo-apollo {
    &:before {
        content: $plogo-apollo;
    }
}

.plogo-apple {
    &:before {
        content: $plogo-apple;
    }
}

.plogo-astrojet {
    &:before {
        content: $plogo-astrojet;
    }
}

.plogo-brother {
    &:before {
        content: $plogo-brother;
    }
}

.plogo-bryce {
    &:before {
        content: $plogo-bryce;
    }
}

.plogo-canon {
    &:before {
        content: $plogo-canon;
    }
}

.plogo-compaq {
    &:before {
        content: $plogo-compaq;
    }
}

.plogo-dell {
    &:before {
        content: $plogo-dell;
    }
}

.plogo-epson {
    &:before {
        content: $plogo-epson;
    }
}

.plogo-hasler {
    &:before {
        content: $plogo-hasler;
    }
}

.plogo-hp {
    &:before {
        content: $plogo-hp;
    }
}

.plogo-ibm {
    &:before {
        content: $plogo-ibm;
    }
}

.plogo-kodak {
    &:before {
        content: $plogo-kodak;
    }
}

.plogo-konica-minolta {
    &:before {
        content: $plogo-konica-minolta;
    }
}

.plogo-kyocera-mita {
    &:before {
        content: $plogo-kyocera-mita;
    }
}

.plogo-lexmark {
    &:before {
        content: $plogo-lexmark;
    }
}

.plogo-mimaki {
    &:before {
        content: $plogo-mimaki;
    }
}

.plogo-mutoh {
    &:before {
        content: $plogo-mutoh;
    }
}

.plogo-neopost {
    &:before {
        content: $plogo-neopost;
    }
}

.plogo-okidata {
    &:before {
        content: $plogo-okidata;
    }
}

.plogo-panasonic {
    &:before {
        content: $plogo-panasonic;
    }
}

.plogo-pitney-bowes {
    &:before {
        content: $plogo-pitney-bowes;
    }
}

.plogo-rena {
    &:before {
        content: $plogo-rena;
    }
}

.plogo-ricoh {
    &:before {
        content: $plogo-ricoh;
    }
}

.plogo-roland {
    &:before {
        content: $plogo-roland;
    }
}

.plogo-samsung {
    &:before {
        content: $plogo-samsung;
    }
}

.plogo-secap {
    &:before {
        content: $plogo-secap;
    }
}

.plogo-sharp {
    &:before {
        content: $plogo-sharp;
    }
}

.plogo-xerox {
    &:before {
        content: $plogo-xerox;
    }
}
