$icomoon-font-family: "printerLogo" !default;
$icomoon-font-path: "fonts" !default;

$plogo-apollo: "\e900";
$plogo-apple: "\e901";
$plogo-astrojet: "\e902";
$plogo-brother: "\e903";
$plogo-bryce: "\e904";
$plogo-canon: "\e905";
$plogo-compaq: "\e906";
$plogo-dell: "\e907";
$plogo-epson: "\e908";
$plogo-hasler: "\e909";
$plogo-hp: "\e90a";
$plogo-ibm: "\e90b";
$plogo-kodak: "\e90c";
$plogo-konica-minolta: "\e90d";
$plogo-kyocera-mita: "\e90e";
$plogo-lexmark: "\e90f";
$plogo-mimaki: "\e910";
$plogo-mutoh: "\e911";
$plogo-neopost: "\e912";
$plogo-okidata: "\e913";
$plogo-panasonic: "\e914";
$plogo-pitney-bowes: "\e915";
$plogo-rena: "\e916";
$plogo-ricoh: "\e917";
$plogo-roland: "\e918";
$plogo-samsung: "\e919";
$plogo-secap: "\e91a";
$plogo-sharp: "\e91b";
$plogo-xerox: "\e91c";

